
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
// components
import list from "@/components/common/list.vue";
import newsItem from "@/components/common/NewsItem.vue";
import MenuTab from "@/components/common/MenuTab.vue";
import banner from "@/components/common/Swiper.vue";
import api from "@/api/axios";

export default defineComponent({
  name: "News",
  components: {
    list,
    newsItem,
    MenuTab,
    banner
  },
  setup() {
    const list = ref(null);
    api.get("/M/News/newbanner").then((res): void => {
      list.value = res.data.obj.listimg;
    });


    //  切换列表菜单
    type Menu = "全部" | "公司动态" | "行业新闻" | "技术前沿"| "文化长廊";
    const menu: Menu[] = ["全部", "公司动态", "行业新闻", "技术前沿", "文化长廊"];
    const currentMenuItem = ref(menu[1]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    return {
      menu,
      currentMenuItem,
      selectMenu,
      list
    };
  },
});
